"use client";

import React, { createContext, useState } from "react";

const SurveyContext = createContext<any>(false);

/**
 * A React context provider component to store the users survey objectId once a survey is completed.
 *
 * @remarks
 * This context provider component sets and stores the users Parse objectId for the Survey object.
 *
 * @param param0
 * @returns - The SurveyContext provider component in JSX.
 */
export function SurveyProvider({ children }: { children: React.ReactNode }) {
  const [survey, setSurvey] = useState("");

  return (
    <SurveyContext.Provider value={{ survey, setSurvey }}>
      {children}
    </SurveyContext.Provider>
  );
}

export default SurveyContext;
