import * as React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

/**
 * A React component that calculates the current year and prints out the copyright line
 * for the bottom of a web page.
 *
 * @param props - React props is not currently used.
 * @returns - The Copyright React component in JSX.
 */
export default function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        YEX
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
