import shuffleArray from "./shuffleArray";

export default function shuffleAllIndexes(len : number) {

    //  Given an Array length, fill the array with consecutuve integers from 0
    //  to lengh-1 and shuffle the array.
    
    const arr = Array.from(Array(len).keys());

    const shuffledArray = shuffleArray(arr);

    return shuffledArray;

}