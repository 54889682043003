"use client";

import { ISurveyInput } from "@/app/@types/surveyinputobj";
import React, { createContext, useState } from "react";

const SurveyDataContext = createContext<any>({});

/**
 * A React context provider component for the YEX survey results.
 *
 * @remarks
 * The context provider component is used to set and store the YEX survey results.
 *
 * @param param0
 * @returns - The React SurveyContext provider in JSX.
 */
export function SurveyDataProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [surveyData, setSurveyData] = useState<ISurveyInput | null>(null);

  return (
    <SurveyDataContext.Provider value={{ surveyData, setSurveyData }}>
      {children}
    </SurveyDataContext.Provider>
  );
}

export default SurveyDataContext;
