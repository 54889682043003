
'use client';
import * as React from 'react';
import { useContext } from 'react';
import UserContext from '@/context/userContext';
import Copyright from '@/components/Copyright';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TopNav from '@/components/TopNav';
import MainBody from '@/components/MainBody';
import { Stack } from '@mui/material';

export default function Home() {

  const {user, setUser} = useContext(UserContext);

  return (
    <Container component="main" maxWidth="xl">
      <Box
        sx={{
          marginTop: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          
        }}
      >
        <TopNav />
        <MainBody />
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}