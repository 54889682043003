"use client";

import React, { createContext, useContext, useEffect, useState } from "react";
// import Parse from "parse/dist/parse";
const Parse = require("parse");
import { useParseContext } from "@/context/parseContext";
import { ExperienceObj } from "@/app/@types/experienceobj";

const ExperienceContext = createContext<ExperienceObj[]>([]);

function ExArrInit(experiences: Parse.Object[]): ExperienceObj[] {
  return experiences
    .filter((experience) => experience.get("active"))
    .map((experience) => ({
      id: experience.id,
      category: experience.get("category"),
      name: experience.get("name"),
      use: experience.get("use"),
      results: experience.get("results"),
      image: experience.get("image"),
    }));
}

/**
 * A React context provider component to store the YEX experiences.
 *
 * @remarks
 * This component fetches the YEX experiences stored in the Parse DB, and stores
 * them in a React state variable.
 * @param param0
 * @returns
 * The ExperienceContext Provider in JSX.
 */
export function ExperiencesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [experiences, setExperiences] = useState<ExperienceObj[]>([]);
  const parse = useParseContext();

  useEffect(() => {
    async function fetchExperiences() {
      const fetchedExs: Parse.Object[] = await new parse.Query(
        "Experiences"
      ).findAll();

      setExperiences(ExArrInit(fetchedExs));
    }
    fetchExperiences().catch(console.error);
    // eslint-disable-next-line
  }, [setExperiences]);

  return (
    <ExperienceContext.Provider value={experiences}>
      {children}
    </ExperienceContext.Provider>
  );
}

export default ExperienceContext;
