"use client";
import * as React from "react";
import { useContext } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CheckIcon from "@mui/icons-material/Check";
import SelectedExper from "./SelectedExper";
import { Controller, useFormContext } from "react-hook-form";
import ExperienceContext from "@/context/experienceContext";
import SelectedExperContext from "@/context/selectedExperContext";
import UserContext from "@/context/userContext";
import SurveyDataContext from "@/context/surveyDataContext";
import { ExperienceObj } from "@/app/@types/experienceobj";

/**
 * A React component to allow the user to select YEX experience(s).
 *
 * @remarks
 * This component allows the user to manually select their YEX experience(s)
 * instead of the experiences recommended per the survey response.
 * This component is a parent component to the
 * SelectedExper component that holds the individual experience(s).
 *
 * @param props - React props not currently used.
 * @returns - The SelectedExperPanel React component in JSX.
 */

export default function UserSelectExperPanel(props: any) {
  const { selectedExper, setSelectedExper } = useContext(SelectedExperContext);
  const { setUser } = useContext(UserContext);
  const { setSurveyData } = useContext(SurveyDataContext);

  const experiences = useContext(ExperienceContext);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [selectedExperiences, setSelectedExperiences] = React.useState<
    ExperienceObj[]
  >([]);

  let dispExper = selectedExperiences.map((experience, index) => (
    <Grid
      key={index}
      item
      xs={6}
      style={{
        display: "flex",
      }}
    >
      <SelectedExper {...experience} />
    </Grid>
  ));

  return (
    <Stack spacing={1} alignItems="center" sx={{ mt: 2 }}>
      <Paper
        sx={{ bgcolor: "rgba(0,0,0,0.25)", color: "secondary.dark" }}
        elevation={3}
        square={false}
      >
        <Stack spacing={1}>
          <Typography sx={{ fontSize: 24 }}>
            Please select one or two experience(s):
          </Typography>
          <Paper sx={{ bgcolor: "white", padding: 1 }}>
            <Controller
              name="selectValues"
              control={control}
              defaultValue={[] as ExperienceObj[]} // ensure the default value is an empty array, not undefined
              render={({
                field: { onChange, value },
                fieldState: { error },
                formState,
              }) => (
                <Autocomplete
                  // {...field}
                  sx={{ m: 1, width: 500 }}
                  multiple
                  options={experiences}
                  getOptionLabel={(option: ExperienceObj) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disableCloseOnSelect
                  value={value}
                  onChange={(event, newValue) => {
                    const limitedValue = newValue.slice(0, 2);
                    onChange(limitedValue);
                    setSelectedExperiences(limitedValue);
                    // console.log(limitedValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Experiences"
                      placeholder="Experiences"
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <MenuItem
                      {...props}
                      key={option.id}
                      value={option.name}
                      sx={{ justifyContent: "space-between" }}
                    >
                      {option.name}
                      {selected ? <CheckIcon color="info" /> : null}
                    </MenuItem>
                  )}
                />
              )}
            />
          </Paper>
          <Paper sx={{ padding: 3 }}>
            <Container>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                  {dispExper}
                </Stack>
              </Stack>
            </Container>
          </Paper>
        </Stack>
      </Paper>
    </Stack>
  );
}
