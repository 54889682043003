"use client";

import { ExpFormInput } from "@/app/@types/experienceinputobj";
import React, { createContext, useState } from "react";

const SelectedExperContext = createContext<any>({});

/**
 * A React context provider component to store the selected experiences.
 *
 * @remarks
 * This context provider component sets and stores the experiences that were
 * selected by the end user in the survey page.
 *
 * @param param0
 * @returns - The SelectedExperContext provider component in JSX.
 */
export function SelectedExperProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [selectedExper, setSelectedExper] = useState<ExpFormInput | null>(null);

  return (
    <SelectedExperContext.Provider value={{ selectedExper, setSelectedExper }}>
      {children}
    </SelectedExperContext.Provider>
  );
}

export default SelectedExperContext;
