"use client";

import React, { createContext, useContext } from "react";
// import Parse from "parse/dist/parse";
const Parse = require("parse");
const PARSE_APPLICATION_ID = process.env.NEXT_PUBLIC_PARSE_APPLICATION_ID;
const PARSE_JAVASCRIPT_KEY = process.env.NEXT_PUBLIC_PARSE_JAVASCRIPT_KEY;
Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
Parse.serverURL = "https://parseapi.back4app.com/";
const ParseContext = createContext(Parse);

/**
 * A React contxt provider to store the Parse DB connection.
 *
 * @remarks
 * This component stores and retreives the Parse DB connection.
 * @param param0
 * @returns - The ParseContext provider in JSX.
 */
export function ParseProvider({ children }: { children: React.ReactNode }) {
  return (
    <ParseContext.Provider value={Parse}>{children}</ParseContext.Provider>
  );
}

export function useParseContext() {
  const context = useContext(ParseContext);

  if (context === undefined) {
    throw new Error("ParseContext must be used within a ParseProvider");
  }
  return context;
}

export default ParseContext;
