"use client";
import * as React from "react";
import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Experience from "./Experience";
import ExperienceContext from "@/context/experienceContext";
import { ExperienceObj } from "@/app/@types/experienceobj";
import SurveyDataContext from "@/context/surveyDataContext";
import QCategoryContext from "@/context/qcategoryContext";
import genExperienceIds from "@/app/util/genExperienceIds";
import shuffleAllIndexes from "@/app/util/shuffleAllIndexes";

/**
 * A React child component that retrieves the available YEX experiencs and
 * calculates the three primary experience options.
 *
 * @remarks
 * The component calculates the experience options based upon the answers
 * provided in the survey page based upon the yes selections, or randomly
 * if all questions are answered "no".  The component uses the React Context
 * API to retreive the survey results, the experience options, as well as the
 * categories that the questions fall into (used by the experience selection
 * algorithm).  The component is a parent to the Experience component and child
 * to the RecPannel component.
 *
 * @param props - React props used to determine if the request is for the
 *                primary or secondary experience.
 * @returns - The RecPannelInner React component in JSX.
 */
export default function RecPanelInner(props: any) {
  const [experienceIndex1, setExperienceIndex1] = useState(2);
  const [experienceIndex2, setExperienceIndex2] = useState(4);
  const [experienceIndex3, setExperienceIndex3] = useState(6);
  const [experiences, setExperiences] = useState(
    null as ExperienceObj[] | null | undefined
  );

  const { surveyData } = useContext(SurveyDataContext);
  const qcategories = useContext(QCategoryContext);
  const fetchedExs = useContext(ExperienceContext);

  // console.log("In RecPanelInner");
  // console.log(survey);
  // console.log(secondary);
  // console.log(qcategories);

  useEffect(() => {
    if (fetchedExs != null) {
      setExperiences(fetchedExs);
    }
  }, [fetchedExs]);

  useEffect(() => {
    if (experiences) {
      const experienceIds = genExperienceIds(
        surveyData,
        qcategories,
        props.secondary
      );
      if (experienceIds != null && experiences[0].id != "") {
        setExperienceIndex1(
          experiences.findIndex((item) => item.id === experienceIds[0])
        );
        setExperienceIndex2(
          experiences.findIndex((item) => item.id === experienceIds[1])
        );
        setExperienceIndex3(
          experiences.findIndex((item) => item.id === experienceIds[2])
        );
      } else {
        const indexes = shuffleAllIndexes(experiences.length);
        setExperienceIndex1(indexes[0]);
        setExperienceIndex2(indexes[1]);
        setExperienceIndex3(indexes[2]);
      }
    }
  }, [experiences, surveyData, qcategories, props.secondary]);

  if (experiences == null) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box>
      <Grid
        container
        columnSpacing="30"
        rowSpacing="3"
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        style={{ marginTop: "20px" }}
      >
        <Grid item xs={4} flexGrow={1}>
          <Experience {...experiences[experienceIndex1]} />
        </Grid>
        <Grid item xs={4} flexGrow={1}>
          <Experience {...experiences[experienceIndex2]} />
        </Grid>
        <Grid item xs={4} flexGrow={1}>
          <Experience {...experiences[experienceIndex3]} />
        </Grid>
      </Grid>
    </Box>
  );
}
