"use client";

import React, { createContext, useState } from "react";

const CustomExperContext = createContext<any>(false);

/**
 * A React context provider component to store the users desire to select their own experience(s).
 *
 * @remarks
 * This context provider component sets and stores the users desire to select their own experience(s).
 * instead of the recommended experiences derived from the survey page answers.
 *
 * @param param0
 * @returns - The CustomExperContext provider component in JSX.
 */
export function CustomExperProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [customExper, setCustomExper] = useState(false);

  return (
    <CustomExperContext.Provider value={{ customExper, setCustomExper }}>
      {children}
    </CustomExperContext.Provider>
  );
}

export default CustomExperContext;
