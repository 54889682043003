"use client";

import React, { createContext, useContext, useEffect, useState } from "react";
// import Parse from "parse/dist/parse";
const Parse = require("parse");
import { useParseContext } from "@/context/parseContext";
import { QuestionObj } from "@/app/@types/questionobj";

const QuestionsContext = createContext<QuestionObj[]>([]);

function QArrInit(questions: Parse.Object[]): QuestionObj[] {
  return questions.map((question) => ({
    id: question.id,
    category: question.get("category"),
    question: question.get("question"),
  }));
}

/**
 * A React context provider component to retreive and store the survey questions.
 *
 * @returns
 * This context provider component retreives and stores the survey questions from
 * the Parse DB.
 * @param param0
 * @returns - The QuestionsContext provider in JSX.
 */

export function QuestionsProvider({ children }: { children: React.ReactNode }) {
  // const questions = fetchQuestions();
  const [questions, setQuestions] = useState<QuestionObj[]>([]);
  const parse = useParseContext();

  useEffect(() => {
    async function fetchQuestions() {
      const fetchedQs: Parse.Object[] = await new parse.Query(
        "Questions"
      ).findAll();

      setQuestions(QArrInit(fetchedQs));
    }
    fetchQuestions().catch(console.error);
    // eslint-disable-next-line
  }, []);

  return (
    <QuestionsContext.Provider value={questions}>
      {children}
    </QuestionsContext.Provider>
  );
}

export default QuestionsContext;
