"use client";
import * as React from "react";
import { useContext } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { object, array, number, string, ObjectSchema } from "yup";
import UserContext from "@/context/userContext";
import SurveyContext from "@/context/surveyContext";
import ParseContext from "@/context/parseContext";
import SelectedExperContext from "@/context/selectedExperContext";
import CustomExperContext from "@/context/customExperContext";
import { ExperienceObj } from "@/app/@types/experienceobj";
import { ExpFormInput } from "@/app/@types/experienceinputobj";
import { SelectFormInput } from "@/app/@types/selectedexperienceinputobj";
import UserSelectExperPanel from "./UserSelectExperPanel";

const experienceObjSchema: ObjectSchema<ExperienceObj> = object({
  id: string().defined(),
  category: string().defined(),
  name: string().defined(),
  use: string().defined(),
  results: string().defined(),
  image: string().defined(),
});

const schema: ObjectSchema<SelectFormInput> = object({
  selectValues: array()
    .of(experienceObjSchema)
    .required()
    .min(1, "Please Select a Primary Experience."),
});

/**
 * A React component to facilitate the selection of the primary and secondary
 * recommended YEX experiences.
 *
 * @remarks
 * This is the parent component to hold the primary and secondary experience
 * selection panel child compoenent UserSelectExperPanel
 * The experience options are manually selected by the user instead of being
 * calculated from the survey answers.  The component uses the
 * React Context API to access the YEX user ID, the Parse DB connection, and
 * to store the selected experience.  The component also uses the React Hook Form
 * library to handle the form data.
 *
 * @returns - The UserSelectForm React component in JSX.
 */
export const UserSelectForm = () => {
  const { user } = useContext(UserContext);
  const { survey } = useContext(SurveyContext);
  const parse = useContext(ParseContext);
  const { setSelectedExper } = useContext(SelectedExperContext);
  const { setCustomExper } = useContext(CustomExperContext);

  const createExperience = (
    experienceObj: ExperienceObj,
    isPrimary: boolean
  ) => {
    const experience = new parse.Object("Experiences");
    experience.set("objectId", experienceObj.id);
    const surveyObj = new parse.Object("Surveys");
    surveyObj.set("objectId", survey);

    const selectedExperience = new parse.Object("SelectedExperiences");
    selectedExperience.set("userId", user);
    selectedExperience.set("experienceId", experience);
    selectedExperience.set("primaryExperience", isPrimary);
    selectedExperience.set("surveyId", surveyObj);

    return selectedExperience;
  };

  const persistExperience = async function (
    data: SelectFormInput
  ): Promise<boolean> {
    let SelectedExperience1, SelectedExperience2;

    if (data.selectValues[0]) {
      SelectedExperience1 = createExperience(data.selectValues[0], true);
    }

    if (data.selectValues[1]) {
      SelectedExperience2 = createExperience(data.selectValues[1], false);
    }

    try {
      const promises = [];
      if (SelectedExperience1) promises.push(SelectedExperience1.save());
      if (SelectedExperience2) promises.push(SelectedExperience2.save());

      promises.push(
        fetch("/api/send", {
          method: "POST",
          body: JSON.stringify({
            experience1: data.selectValues[0].name,
            experience2: data.selectValues[1] ? data.selectValues[1].name : "",
            email: user.get("username"),
          }),
        })
      );

      await Promise.all(promises);

      const selectedExperData: ExpFormInput = {
        radioValue: data.selectValues[0].name + ":" + data.selectValues[0].id,
        radioValue2: data.selectValues[1]
          ? data.selectValues[1].name + ":" + data.selectValues[1].id
          : "",
      };

      setSelectedExper(selectedExperData);

      return true;
    } catch (error: any) {
      console.log(`Error! ${error.message}`);
      methods.setError("selectValues", {
        type: "server",
        message: error.message,
      });
      return false;
    }
  };

  const methods = useForm<SelectFormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<SelectFormInput> = (data: SelectFormInput) => {
    // console.log("onSubmit");
    // console.log(data);
    persistExperience(data);
  };

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <UserSelectExperPanel />

        <Button type="submit" fullWidth variant="contained">
          Submit My Selected Experiences
        </Button>
      </Box>
    </FormProvider>
  );
};
