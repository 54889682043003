"use client";

import React, { createContext, useState } from "react";

const SurveyReminderContext = createContext<any>(false);

/**
 * A React context provider component to track if a Post Expereince
 * survey has been completed today to enable or disable the reminder alert box.
 *
 * @remarks
 * This context provider component sets a boolean to true if a Post Experience Survey
 * has been completed today.
 *
 * @param param0
 * @returns - The SurveyContext provider component in JSX.
 */
export function SurveyReminderProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [surveyReminder, setSurveyReminder] = useState(true);

  return (
    <SurveyReminderContext.Provider
      value={{ surveyReminder, setSurveyReminder }}
    >
      {children}
    </SurveyReminderContext.Provider>
  );
}

export default SurveyReminderContext;
