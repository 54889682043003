"use client";

import React, { createContext, useState } from "react";
const Parse = require("parse");

const UserContext = createContext<any>({});

/**
 * A React context provider for the YEX user ID.
 *
 * @remarks
 * The context provider component sets and stores the YEX user ID.
 * @param param0
 * @returns - The React UserContext provider in JSX.
 */
export function UserProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<Parse.Object | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
