"use client";

import React from "react";
/**
 * A React component to hold the quesiton label for the form component in the parent
 * QuestionForm component.
 *
 * @param props - hold the question to label the form component in the QuestionForm component.
 * @returns - The QuestionFormLabel component in JSX.
 */
export const QuestionFormLabel = (props: any) => {
  const question = props.question;

  if (question != null) return <>{question}</>;
  else return <div></div>;
};
