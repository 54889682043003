"use client";
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import BasicPopover from "./BasicPopover";
import { ExperienceObj } from "@/app/@types/experienceobj";

/**
 * A React component used to display a single YEX experience.
 *
 * @remarks
 * This component displays a single YEX experience in the panel of three YEX
 * experience options.  It is a child to the RecPanelInner component that holds
 * three Experience components.  It is also a parent to the BasicPopover component
 * used to display the experience indended results.
 * @param {id, category, name, results, use, image} : ExperienceObj
 * @returns
 */
export default function Experience({
  id,
  category,
  name,
  results,
  use,
  image,
}: ExperienceObj) {
  return (
    <Box>
      <Card style={{ padding: "10px", marginBottom: "30px" }}>
        <Typography align="center">
          <CardHeader
            sx={{ display: "inline", alignItems: "center" }}
            action={
              <FormControlLabel
                value={name + ":" + id}
                control={<Radio />}
                label={<Typography sx={{ fontSize: 24 }}>{name}</Typography>}
              />
            }
          />
        </Typography>
        <CardMedia
          component="img"
          sx={{ height: 540, objectFit: "contain" }}
          image={"/" + image}
          title={name}
        />
        <CardContent>
          <Typography variant="body1" style={{ height: "55px" }}>
            {use}
          </Typography>
        </CardContent>
        <CardActions sx={{ alignItems: "center", justifyContent: "center" }}>
          <BasicPopover results={results} />
        </CardActions>
      </Card>
    </Box>
  );
}
