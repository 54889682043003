import { QCatObj } from "../@types/qcategoryobj";
import { ISurveyInput } from "../@types/surveyinputobj";
import shuffleArray from "./shuffleArray";

/**
 * Generate the three YEX experience IDs to be recommended to the YEX user.
 *
 * @remarks
 * This utility function selects three YEX experiences based upon the survey answers
 * provided by the YEX user.  For a primary experience, the first "yes" response in
 * the survey is used to extract the question category which then maps to a set of
 * experiences to choose from.  For example, questions in the "Mental Relaxation" category
 * currently map to the VIBE BED, MUSE, and RED LIGHT experiences options.  Once the
 * set of experience options is extracted, the array of options is shuffled.  The first
 * three experiences in the randomized array are returned to the end user to be
 * recommended as a primary or secondary experience.  If the YEX user answers "No" to
 * all of the survey questions, then the utility function returns null.
 *
 * @param surveyRes - An ISurveyInput object storing the YEX users survey answers.
 * @param qcategories - An array of QCatObj that holds the experience options for each question category.
 * @param secondary - A boolean that indicates if this is for a primary or secondary experience.
 * @returns - Array of three YEX experiences mapping to the question category or null.
 */
export default function genExperienceIds(
  surveyRes: ISurveyInput,
  qcategories: QCatObj[],
  secondary: boolean
) {
  /**
   * Calculate three YEX experiences based upon the question category.
   *
   * @remarks
   * The function starts with the array of all possible question categories, then filters out
   * all but the entry specific to categoryString.  The remaining entry is then used to retrieve
   * the available experience options specific to the indicated category.  The array of available
   * experiences is then shuffled, and the first three entries are returned.
   *
   * @param categoryString - The question category used to select the set of experiences to pull from.
   * @returns - An array of three YEX experiences randomized from the question category experience options.
   */
  function getIds(categoryString: any) {
    // find the QCatObj instance that matches the category from the survey
    const result = qcategories.find(
      ({ category }) => category === categoryString
    );

    // Return the first three id's in the shuffled array
    return shuffleArray(result?.experienceOptions ?? []).slice(0, 3);
  }

  function getYesResponseIds(response: string) {
    if (response == null) return null;

    if (response.indexOf("yes") === 0) {
      return getIds(response.split(":")[1]);
    }
    return null;
  }

  if (surveyRes == null) return null;

  const yesResponses = [
    surveyRes.radioValue1,
    surveyRes.radioValue2,
    surveyRes.radioValue3,
    surveyRes.radioValue4,
  ].filter((response) => response.includes("yes"));

  return getYesResponseIds(yesResponses[secondary ? 1 : 0]);
}
