"use client";

import React, { createContext, useContext, useEffect, useState } from "react";
// import Parse from "parse/dist/parse";
const Parse = require("parse");
import { useParseContext } from "@/context/parseContext";
import { QCatObj } from "@/app/@types/qcategoryobj";

const QCategoryContext = createContext<QCatObj[]>([]);

/**
 * A React context provider to store the question categories.
 *
 * @remarks
 * This context provider component stores the different question categories used
 * to select the recommended experience from the end user survey response.
 *
 * @returns - The QCategoryContext provider in JSX.
 */
export function QCategoriesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [qcategories, setQCategories] = useState<QCatObj[]>([]);
  const parse = useParseContext();

  useEffect(() => {
    async function fetchQCategories() {
      let fetchedQCats = await new parse.Query("QuestionCategories").find();
      for (let experience of fetchedQCats) {
        let experienceRelation = experience.relation("experienceOptions");
        experience.experienceObjects = await experienceRelation.query().find();
      }

      function QCatArrInit(qcategories: Parse.Object[]) {
        // Convert the array of Parse Objects into an array of QCatObj (Question Category) Objects
        let qCatArray: QCatObj[] = [];
        if (qcategories.length > 0) {
          for (var i = 0; i < qcategories.length; i++) {
            let expObjects = (qcategories[i] as any).experienceObjects;
            let experienceOptions = [];
            for (var j = 0; j < expObjects.length; j++) {
              experienceOptions[j] = expObjects[j].id;
            }
            qCatArray.push({
              id: qcategories[i].id,
              category: qcategories[i].get("category"),
              experienceOptions: experienceOptions,
            });
          }
        }
        return qCatArray;
      }

      setQCategories(QCatArrInit(fetchedQCats));
    }
    fetchQCategories().catch(console.error);
  }, [parse.Query]);

  return (
    <QCategoryContext.Provider value={qcategories}>
      {children}
    </QCategoryContext.Provider>
  );
}

export default QCategoryContext;
