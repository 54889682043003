export default function stringToBoolean(str: string) {
    
    switch(str.toLowerCase()?.trim()) {
        case "yes":
          return true;
        case "no":
          return false;
        case null:
        case undefined:
          return false;
      }
}