"use client";
import * as React from "react";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import RecPanelInner from "./RecPanelInner";
import { Controller, useFormContext } from "react-hook-form";

/**
 * A React component used to display the secondary YEX experience options.
 *
 * @remarks
 * This component is a child to CompSlide and Parent to RecPannelInner.  It is a shell
 * component used to label the second instance of RecPannelInner, and also hold the
 * radio button group for the secondary experiences.  This component uses the React
 * Hook Form library to handle the radio button form data.
 *
 * @param props - React props are not currently used.
 * @returns - The CompSlideInner React component in JSX.
 */
export default function CompSlideInner(props: any) {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <Stack spacing={1} alignItems="center">
      <Paper
        sx={{ bgcolor: "rgba(0,0,0,0.25)", color: "secondary.dark" }}
        elevation={3}
        square={false}
      >
        <FormControl>
          <FormLabel
            sx={{
              fontSize: 24,
              color: "black",
              "&, &.Mui-focused": { color: "black" },
            }}
            id="radio-buttons-card-group2-label"
          >
            Based on your responses we recommend the following secondary user
            experiences:
            <Controller
              name="radioValue2"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
                formState,
              }) => (
                <RadioGroup
                  aria-labelledby="radio-buttons-card-group2-label"
                  name="radio-buttons-card-group2"
                  value={value}
                  onChange={onChange}
                  row
                >
                  <Paper>
                    <RecPanelInner secondary={true} />
                  </Paper>
                </RadioGroup>
              )}
            />
          </FormLabel>
        </FormControl>
      </Paper>
    </Stack>
  );
}
