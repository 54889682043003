/**
 * Calculate a random integer between min and max.
 * @param min - The lower bound integer for the interval.
 * @param max - The upper bound integer for the interval.
 * @returns - A random integer between min and max.
 */
export default function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}
