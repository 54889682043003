"use client";
import * as React from "react";
import { useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import { QuestionForm } from "./QuestionForm";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import UserContext from "@/context/userContext";
import ParseContext from "@/context/parseContext";
import SurveyDataContext from "@/context/surveyDataContext";
import SurveyContext from "@/context/surveyContext";
import { ISurveyInput } from "@/app/@types/surveyinputobj";
import shuffleArray from "@/app/util/shuffleArray";
import stringToBoolean from "@/app/util/stringToBoolean";
import { useForm } from "react-hook-form";
import { object } from "yup";

const bodstyle = {
  marginTop: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  bgcolor: "primary.main",
  width: 1,
};

const defaultValues = {
  radioValue1: "",
  radioValue2: "",
  radioValue3: "",
  radioValue4: "",
};

const questionCategories = [
  "Stress Relief",
  "Physical Fatigue Relief",
  "Mental Relaxation",
  "Improve Focus",
];

/**
 * A React component to display four survey questions and persist the responses in a Parse DB.
 *
 * @remarks
 * This is the parent component that combines child QuestionForm components for the survey.
 * The questions fall into four categories: Stress Relief, Physical Fatigue Relief, Mental
 * Relaxation, and Improve Focus.  The four categories are shuffled into a random order and
 * one question from each category is selected for the survey. The React Hook Form library is used
 * to handle the form data and the React Context API (useContext) is used for persistence of
 * the survey response, the Parse database connection, and the YEX user ID.
 *
 * @param props - React component props not currently used.
 * @returns - The SurveyForm React Component in JSX.
 */
export default function SurveyForm(props: any) {
  const { user } = useContext(UserContext);
  const { setSurveyData } = useContext(SurveyDataContext);
  const { setSurvey } = useContext(SurveyContext);
  const parse = useContext(ParseContext);

  // Shuffle the array of categories to select random display order of categories
  const [shuffledCategories, setShuffledCategories] = React.useState<string[]>(
    []
  );

  useEffect(() => {
    setShuffledCategories(shuffleArray(questionCategories));
  }, []);

  const persistSurvey = async function (data: ISurveyInput): Promise<boolean> {
    const responses = [];
    const questions = [];
    const questionIds = [];
    let savedSurvey;

    const survey = new parse.Object("Surveys");
    try {
      savedSurvey = await survey.save();
    } catch (error: any) {
      console.error(`Error! ${error.message}`);
      return false;
    }

    for (let i = 1; i <= 4; i++) {
      const question = new parse.Object("Questions");
      const objectIdString = (data as any)[`radioValue${i}`].split(":")[2];
      question.set("objectId", objectIdString);
      questions.push(question);
      questionIds.push(objectIdString);
      console.log(objectIdString);

      const response = new parse.Object("Responses");
      response.set("userId", user);
      response.set("questionId", question);
      response.set("surveyId", savedSurvey);
      response.set(
        "responseChoice",
        stringToBoolean((data as any)[`radioValue${i}`].split(":")[0])
      );
      response.set("responseOrder", i);
      responses.push(response);
    }

    try {
      await Promise.all(responses.map((response) => response.save()));
      setSurveyData(data);
      console.log(savedSurvey);
      const relation = savedSurvey.relation("randomQuestions", "Questions");
      console.log(relation);
      console.log(questionIds.length);
      for (let i = 0; i < questionIds.length; i++) {
        console.log(questionIds[i]);
        const query = new parse.Query("Questions");
        try {
          const question = await query.get(questionIds[i]);
          console.log(question);
          relation.add(question);
          console.log(relation);
        } catch (error: any) {
          console.error(`Error! ${error.message}`);
          return false;
        }
      }
      const saveRelationSurvey = await savedSurvey.save();
      console.log(saveRelationSurvey);
      setSurvey(savedSurvey.id);
      return true;
    } catch (error: any) {
      console.error(`Error! ${error.message}`);
      return false;
    }
  };

  const { handleSubmit, reset, control, setValue } = useForm<ISurveyInput>({
    defaultValues: defaultValues,
  });

  const onSubmit = (data: ISurveyInput) => {
    // console.log(data);
    // console.log(shuffledCategories);
    persistSurvey(data);
  };

  return (
    <Box component="form" sx={{ ...bodstyle }}>
      <Stack
        spacing={2}
        sx={{
          margin: 5,
          width: 1,
        }}
      >
        {shuffledCategories.map((category: string, index: number) => (
          <QuestionForm
            key={index}
            name={`radioValue${index + 1}`}
            control={control}
            label={`Question ${index + 1}`}
            qcategory={category}
          />
        ))}
        <Button
          onClick={handleSubmit(onSubmit)}
          fullWidth
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            bgcolor: "secondary.light",
            "&:hover": { bgcolor: "secondary.light" },
          }}
        >
          Continue to Recommendations
        </Button>
      </Stack>
    </Box>
  );
}
