"use client";
import * as React from "react";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import RecPanelInner from "./RecPanelInner";
import { Controller, useFormContext, FieldError } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AlertSnackbar from "./AlertSnackbar";

interface IFormInput {
  radioValue: string;
}

const defaultValues = {
  radioValue: "",
};

const schema = yup.object().shape({
  radioValue: yup.string().required(),
});

/**
 * A React child shell component that holds the primary experience selection panel.
 *
 * @remarks
 * This component is a child to RecCompForm, and uses React Hook Form to control the
 * three radio buttons used for the primary experience selection.  It also contains the
 * text label for the primary experience selection panel.
 *
 * @param props - React component props not currently used.
 * @returns - The RecPanel React component in JSX.
 */
export default function RecPanel(props: any) {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <Stack spacing={1} alignItems="center">
      <Paper
        sx={{ bgcolor: "rgba(0,0,0,0.25)", color: "secondary.dark" }}
        elevation={3}
        square={false}
      >
        <FormControl>
          <FormLabel
            sx={{
              fontSize: 24,
              color: "black",
              "&, &.Mui-focused": { color: "black" },
            }}
            id="radio-buttons-card-group-label"
          >
            Based on your responses we recommend the following primary user
            experiences:
            <Controller
              name="radioValue"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
                formState,
              }) => (
                <RadioGroup
                  aria-labelledby="radio-buttons-card-group-label"
                  name="radio-buttons-card-group"
                  value={value}
                  onChange={onChange}
                  row
                >
                  <Paper>
                    {errors.radioValue?.message && (
                      <>
                        <Alert severity="error">
                          {String(errors.radioValue.message)}
                        </Alert>
                        <AlertSnackbar
                          severity="error"
                          message={String(errors.radioValue.message)}
                        />
                      </>
                    )}
                    <RecPanelInner secondary={false} />
                  </Paper>
                </RadioGroup>
              )}
            />
          </FormLabel>
        </FormControl>
      </Paper>
    </Stack>
  );
}
