"use clent";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import UserContext from "@/context/userContext";
import SelectedExperContext from "@/context/selectedExperContext";
import SurveyDataContext from "@/context/surveyDataContext";
import SurveyContext from "@/context/surveyContext";
import CustomExperContext from "@/context/customExperContext";
import SurveyReminderContext from "@/context/surveyReminderContext";
import ParseContext from "@/context/parseContext";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationIcon from "@mui/icons-material/Notifications";
import AccountCircle from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import { useRouter } from "next/navigation";

/**
 * A React component to display the top navigation frame for the YEX web application.
 *
 * @remarks
 * This component uses the React Context API to keep track of the YEX user.  If the
 * YEX user had logged in, an Icon with the user ID is displayed on the right side of
 * the navigation bar.
 *
 * @param props - React component props not currently used.
 * @returns - The TopNav React component in JSX.
 */
export default function TopNav(props: any) {
  const { user } = useContext(UserContext);
  const { setSelectedExper } = useContext(SelectedExperContext);
  const { setSurveyData } = useContext(SurveyDataContext);
  const { setSurvey } = useContext(SurveyContext);
  const { setCustomExper } = useContext(CustomExperContext);
  const { setSurveyReminder } = useContext(SurveyReminderContext);
  const router = useRouter();
  const Parse = useContext(ParseContext);
  const [pendingPostSurveys, setPendingPostSurveys] = useState(0);
  console.log("Parse in TopNav", Parse);

  useEffect(() => {
    async function fetchResponses() {
      if (!user) {
        console.error("User is undefined");
        return 0;
      }
      const userPointer = user.toPointer();
      const twoMonthsAgo = new Date();
      twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const fetchedExperiencesCount = await new Parse.Query(
        "SelectedExperiences"
      )
        .equalTo("userId", userPointer)
        .notEqualTo("donePostSurvey", true)
        .greaterThanOrEqualTo("createdAt", twoMonthsAgo)
        .lessThan("createdAt", yesterday)
        .descending("createdAt")
        .limit(10)
        .count();
      console.log("fetchedExperiencesCount", fetchedExperiencesCount);
      return fetchedExperiencesCount <= 10 ? fetchedExperiencesCount : 10;
    }
    async function setSurveys() {
      const pendingPostSurveys = await fetchResponses();
      console.log("pendingPostSurveys", pendingPostSurveys);
      setPendingPostSurveys(Math.round(pendingPostSurveys / 2));
      if (user && Math.round(pendingPostSurveys / 2) == 0) {
        console.log("TopNav: setting survey reminder to false");
        setSurveyReminder(false);
      }
    }
    console.log("in TopNav useEffect");
    setSurveys();
  }, [Parse, user, pendingPostSurveys, setSurveyReminder]);

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography
          variant="h3"
          align="center"
          component="div"
          sx={{ flexGrow: 1 }}
        >
          Welcome to YEX
        </Typography>
        {user && (
          <div>
            <Stack direction="row">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  router.push("/");
                  setSurveyData(null);
                  setSurvey("");
                  setSelectedExper(null);
                  setCustomExper(false);
                }}
              >
                <HomeIcon />
              </IconButton>
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                <IconButton size="large" color="inherit">
                  <AccountCircle />
                </IconButton>
                {user.get("username")}
              </Typography>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  router.push("/postsurvey");
                }}
              >
                <Badge badgeContent={pendingPostSurveys} color="secondary">
                  <NotificationIcon />
                </Badge>
              </IconButton>
            </Stack>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
