"use client";

import * as React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import CompSlideInner from "./CompSlideInner";

/**
 * A React shell component used to hold the secondary experience selection panel.
 *
 * @remarks
 * A component similar to RecPanel, but display of the experience options is activated
 * with a slider switch.  Child to RecCompForm and parent to CompSlideInner.
 *
 * @param props - React props not currently used.
 * @returns - The CompSlidePanel React component in JSX.
 */

export default function CompSlidePanel(props: any) {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  let compExp;
  if (checked) {
    compExp = <CompSlideInner />;
  }

  return (
    <Paper
      sx={{ bgcolor: "rgba(0,0,0,0.25)", color: "secondary.dark" }}
      elevation={3}
      square={false}
    >
      <Typography sx={{ fontSize: 24 }}>
        Are you interested in a complimentary experience today?
      </Typography>
      <Stack spacing={1} alignItems="center">
        <Paper>
          <Stack direction="row">
            <Typography>No</Typography>
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography>Yes</Typography>
          </Stack>
        </Paper>
        <Paper>{compExp}</Paper>
      </Stack>
    </Paper>
  );
}
